import React from 'react';
import { 
  Box, 
  VStack, 
  Image, 
  HStack, 
  Icon, 
  Link,
  Heading,
  Text
} from '@chakra-ui/react';
import { 
  FaLinkedin, 
  FaTwitter, 
  FaInstagram 
} from 'react-icons/fa';

const TeamMember = ({ name, role, bio, image, social }) => (
  <Box
    bg="gray.800"
    borderRadius="xl"
    p={6}
    borderWidth="1px"
    borderColor="gray.700"
    _hover={{
      borderColor: 'brand.500',
      transform: 'translateY(-4px)',
    }}
    transition="all 0.3s"
  >
    <VStack spacing={4} align="center">
      <Box
        w="200px"
        h="200px"
        borderRadius="full"
        overflow="hidden"
        borderWidth="4px"
        borderColor="brand.500"
      >
        <Image
          src={image}
          alt={name}
          w="full"
          h="full"
          objectFit="cover"
        />
      </Box>
      <VStack spacing={2}>
        <Heading as="h3" size="lg" color="whiteAlpha.900">
          {name}
        </Heading>
        <Text
          color="brand.300"
          fontWeight="medium"
          fontSize="lg"
        >
          {role}
        </Text>
      </VStack>
      <Text color="whiteAlpha.800" textAlign="center">
        {bio}
      </Text>
      <HStack spacing={4} pt={2}>
        {social.linkedin && (
          <Link
            href={social.linkedin}
            isExternal
            color="whiteAlpha.700"
            _hover={{ color: 'brand.300' }}
          >
            <Icon as={FaLinkedin} boxSize={5} />
          </Link>
        )}
        {social.twitter && (
          <Link
            href={social.twitter}
            isExternal
            color="whiteAlpha.700"
            _hover={{ color: 'brand.300' }}
          >
            <Icon as={FaTwitter} boxSize={5} />
          </Link>
        )}
        {social.instagram && (
          <Link
            href={social.instagram}
            isExternal
            color="whiteAlpha.700"
            _hover={{ color: 'brand.300' }}
          >
            <Icon as={FaInstagram} boxSize={5} />
          </Link>
        )}
      </HStack>
    </VStack>
  </Box>
);

export default TeamMember;
