import React from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Icon,
  HStack,
  Skeleton,
} from '@chakra-ui/react';
import { FaMicrophone, FaLayerGroup, FaHeadphones } from 'react-icons/fa';

const StatsCard = ({ stats, isLoading = false }) => {
  const bgColor = useColorModeValue('gray.800', 'gray.800');
  const borderColor = useColorModeValue('gray.700', 'gray.700');
  const textColor = useColorModeValue('whiteAlpha.900', 'whiteAlpha.900');
  const iconColor = useColorModeValue('brand.500', 'brand.500');

  const StatBox = ({ icon, label, value }) => (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      bg={bgColor}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={borderColor}
      rounded={'lg'}
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-2px)',
        shadow: '2xl',
        borderColor: 'brand.500'
      }}
    >
      <HStack spacing={3} mb={2}>
        <Icon as={icon} w={6} h={6} color={iconColor} />
        <StatLabel fontWeight={'medium'} isTruncated color={textColor}>
          {label}
        </StatLabel>
      </HStack>
      {isLoading ? (
        <Skeleton height="36px" width="100px" />
      ) : (
        <StatNumber fontSize={'3xl'} fontWeight={'bold'} color={textColor}>
          {value}
        </StatNumber>
      )}
    </Stat>
  );

  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
      <StatBox
        icon={FaMicrophone}
        label="Total Episodes"
        value={stats?.totalEpisodes || 0}
      />
      <StatBox
        icon={FaLayerGroup}
        label="Total Seasons"
        value={stats?.totalSeasons || 0}
      />
      <StatBox
        icon={FaHeadphones}
        label="Total Content"
        value={`${stats?.duration?.hours || 0}h ${stats?.duration?.minutes || 0}m`}
      />
    </SimpleGrid>
  );
};

export default StatsCard;
