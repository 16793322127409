import React, { useState, useEffect } from 'react';
import { Box, Spinner, VStack, Text } from '@chakra-ui/react';

const PageTransition = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // Scroll to top when component mounts
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Box 
        minH="calc(100vh - 64px)" 
        bg="gray.900" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
      >
        <VStack spacing={4}>
          <Spinner size="xl" color="brand.500" thickness="4px" />
          <Text fontSize="lg" color="whiteAlpha.900">
            Loading...
          </Text>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </Box>
  );
};

export default PageTransition;
