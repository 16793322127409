import React from 'react';
import { Box, Container, HStack, Link, Text, Icon, VStack, Divider } from '@chakra-ui/react';
import { FaSpotify, FaInstagram, FaYoutube, FaFacebook, FaRss } from 'react-icons/fa';

const SocialLink = ({ icon, href, label }) => (
  <Link
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    color="whiteAlpha.700"
    _hover={{ color: 'brand.300' }}
    aria-label={label}
  >
    <Icon as={icon} boxSize={5} />
  </Link>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box bg="gray.800" py={8}>
      <Container maxW="container.xl">
        <VStack spacing={6}>
          <HStack spacing={6}>
            <SocialLink
              icon={FaSpotify}
              href="https://open.spotify.com/show/03UrDnDHONZ92oOpWzfhxx"
              label="Spotify"
            />
            <SocialLink
              icon={FaYoutube}
              href="https://www.youtube.com/c/NamaskarIndia"
              label="YouTube"
            />
            <SocialLink
              icon={FaInstagram}
              href="https://www.instagram.com/aduppala"
              label="Instagram"
            />
            <SocialLink
              icon={FaFacebook}
              href="https://www.facebook.com/namaskarindialive"
              label="Facebook"
            />
            <SocialLink
              icon={FaRss}
              href="https://anchor.fm/s/1012c66c/podcast/rss"
              label="RSS Feed"
            />
          </HStack>

          <Divider borderColor="gray.700" />

          <HStack spacing={4} fontSize="sm" color="whiteAlpha.600">
            <Link href="/privacy" _hover={{ color: 'brand.300' }}>
              Privacy Policy
            </Link>
            <Text>•</Text>
            <Link href="/terms" _hover={{ color: 'brand.300' }}>
              Terms of Use
            </Link>
            <Text>•</Text>
            <Link href="/contact" _hover={{ color: 'brand.300' }}>
              Contact
            </Link>
          </HStack>

          <Text color="whiteAlpha.800" fontSize="sm">
            {currentYear} Namaskar India. All rights reserved.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
