export const episodeTags = {
  // Ancient Indian History Series
  "e5f3c5a8-2e1d-4e4f-9f9b-d85f235d67a9": ["AncientIndia", "IndianHistory", "HistoricalPerspective"], // Indus Valley Civilization
  "b6d8f3a2-1c9e-4f7d-8e6b-c5d2f9b3e4a1": ["AncientIndia", "IndianHistory", "HistoricalPerspective"], // Mauryan Empire
  "a7c9e2b1-3d8f-4c6e-9f5a-b8d7e6c5f4a3": ["AncientIndia", "IndianHistory", "HistoricalPerspective"], // Gupta Empire
  "d4e5f6a7-8b9c-0d1e-2f3g-4h5i6j7k8l9m": ["AncientIndia", "IndianHistory", "HistoricalPerspective"], // Harappan Civilization
  "1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p": ["AncientIndia", "IndianHistory", "HistoricalPerspective"], // Ancient Indian Trade Routes
  "9d449e3e-de26-40e8-99e4-bc52f52aebf5": ["AncientIndia", "IndianHistory"], // The Beginning
  "28e4b8f2-1234-5678-90ab-cdef12345678": ["AncientIndia", "IndianHistory"], // The Rise of Civilization
  "f7ea59de-3c95-41f0-81f1-5ff5ae554384": ["AncientIndia", "IndianHistory"], // Aryavarta
  "d9de1436-4e53-4cbb-9441-c9c4aaa8611e": ["HistoricalPerspective", "AncientIndia", "IndianHistory"], // Through the Lens
  "c347e70a-8210-4b63-94b6-840cde30dc33": ["HistoricalPerspective", "AncientIndia", "IndianHistory"], // Varna Vyavastha
  "774f1b18-fe42-4afb-8771-2407acac3a00": ["AncientIndia", "IndianHistory"], // The Enlightened Ones
  "22f2a3e6-b487-404a-b039-4295a252c32f": ["AncientIndia", "IndianHistory"], // Rise of Dynasties
  "f3cd4b49-8455-41e7-ae3e-c130b70c359a": ["MauryanEmpire", "AncientIndia", "IndianHistory"], // Ascent of Empire
  "c194db89-9685-43d5-a2b3-fceb38d89f6d": ["MauryanEmpire", "AncientIndia", "IndianHistory"], // Era of Peacock
  "ca004c46-db7a-4710-aef7-351fdd1249ea": ["MauryanEmpire", "AncientIndia", "IndianHistory"], // Ashoka
  "7df5e420-4bad-42d3-85f1-858cd73ee9ef": ["HistoricalPerspective", "AncientIndia", "IndianHistory"], // State Emblem
  "2367be56-c75b-404c-89f6-6a99732df51e": ["AncientIndia", "IndianHistory"], // Tamil Dynasties
  "d2e8edf9-caed-4f8e-b503-054e668c8cf9": ["AncientIndia", "IndianHistory"], // Splinters and Fragments
  "b4f2290e-aa59-40d4-bd57-218f526fcee3": ["AncientIndia", "IndianHistory"], // Invasions Galore
  "c6ba8187-6880-4c2a-85b3-bd81e77f353f": ["AncientIndia", "IndianHistory"], // The Dark Age
  "082cd826-cd64-4527-ba94-b30e02e16072": ["GuptaEmpire", "AncientIndia", "IndianHistory"], // Emergence of Hidden Power
  "0511b127-a61a-49c7-8db6-1d23fb8ade80": ["GuptaEmpire", "AncientIndia", "IndianHistory"], // Vikramaditya
  "905b2d1a-89ae-4dc6-9b3c-703e31dc86d5": ["GuptaEmpire", "AncientIndia", "IndianHistory"], // The Golden Age
  "5d0bec57-1b4b-4a49-adca-68e255baf4e2": ["AncientIndia", "IndianHistory"], // The Hun Invasions
  "ed979126-cb91-4e0a-836d-08955c809b91": ["AncientIndia", "IndianHistory"], // South of Vindhyachal
  "1b80d375-d18c-411e-9afe-5691266bb9a6": ["AncientIndia", "IndianHistory"], // The Ajanta Caves

  // Conversation Series
  "c8d7e6f5-4g3h-2i1j-0k9l-8m7n6o5p4q3": ["IndianHistory", "HistoricalPerspective"], // The Art of History
  "b9a8c7d6-e5f4-g3h2-i1j0-k9l8m7n6o5p": ["IndianHistory", "HistoricalPerspective"], // Historiography
  "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p": ["IndianHistory", "HistoricalPerspective"], // Sources of History
  "f4e3d2c1-b0a9-8765-4321-0987654321ab": ["IndianHistory", "HistoricalPerspective"], // Historical Methods
  "9876543a-b1c2-d3e4-f5g6-h7i8j9k0l1m2": ["IndianHistory", "HistoricalPerspective"], // Archaeological Evidence
  "95a903cc-7661-4589-b14c-2cfaf4e43546": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Stories we have silenced Part 1
  "9f05bef9-6ef8-432f-8df6-fe42efd97841": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Stories we have silenced Part 2
  "85fd5ee1-4783-4f43-beff-3d6a5e9fdc72": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Stories we have silenced Part 3
  "583cd925-892c-478b-84d1-ada151c0c4ab": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Re-visioning myths Part 1
  "9ee54f06-6bac-4626-a3f8-7549943bdb13": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Re-visioning myths Part 2
  "ab6a024b-7f3f-4c32-a02f-a3ea2baaea65": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Who created the creator
  "34440120-4857-46a0-ae40-38e7520afac0": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Recipe for feminist mythology Part 1
  "372cf0e3-e44b-4b72-a6d3-51d464fa3450": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Recipe for feminist mythology Part 2
  "6b58b32d-e64a-40e2-b6b3-fbf30b75f0b3": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // A matter of perspective Part 1
  "bbe588e1-4ed5-4eda-bb77-6805b4cfba12": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Reforming relationships with myths Part 1
  "86a13ce8-9e21-4903-9532-fefba3c483b9": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // Reforming relationships with myths Part 2
  "6c58b32d-e64a-40e2-b6b3-fbf30b75f0b4": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // A matter of perspective Part 2

  // Indian Mythology Series
  "b892bd21-6cdd-4711-a91a-2dd83734b6a6": ["Vedas", "IndianMythology", "HinduMythology"], // The Vedas
  "4d40e691-2405-4b45-9bab-1c59348e49bb": ["IndianMythology", "HinduMythology"], // The Blind Men and the Elephant
  "06bb29d3-2682-45dd-b015-7315eb2fc153": ["Vedas", "IndianMythology", "HinduMythology"], // Nasadiya Sukta
  "1e188474-801c-4119-8474-95fb72c1e759": ["Vedas", "IndianMythology", "HinduMythology"], // Hiranyagarbha Sukta
  "39f35ab1-9ad2-4bb5-94b5-a97280871a1b": ["Vedas", "IndianMythology", "HinduMythology"], // Vishwakarma Sukta
  "dc3ba28f-5d40-4dd0-bdff-d6469ad0528c": ["Vedas", "IndianMythology", "HinduMythology"], // Purusha Sukta
  "efa252b8-3f1c-4507-8e44-b1a55c9a0b4c": ["MythologyPerspective", "IndianMythology", "HinduMythology"], // My Idea of God

  // Concept of Time Series
  "7c6b5a4d-3e2f-1g0h-9i8j-7k6l5m4n3o2p": ["Timekeeping", "IndianCalendar", "HinduCalendar"], // Ancient Indian Timekeeping
  "6b5a4c3d-2e1f-0g9h-8i7j-6k5l4m3n2o1p": ["Timekeeping", "IndianCalendar", "HinduCalendar"], // Hindu Calendar System
  "5a4b3c2d-1e0f-9g8h-7i6j-5k4l3m2n1o0p": ["IndianAstronomy", "IndianAstrology", "AstrologyPerspective"], // Astronomical Calculations
  "4d3c2b1a-0f9e-8g7h-6i5j-4k3l2m1n0o9p": ["IndianAstronomy", "IndianAstrology", "AstrologyPerspective"], // Zodiac Systems
  "3c2b1a0d-9e8f-7g6h-5i4j-3k2l1m0n9o8p": ["IndianAstronomy", "IndianAstrology", "AstrologyPerspective"], // Time Cycles
  "b6c2b8ea-2ee6-4244-9d4a-5d4711f572a6": ["Timekeeping", "IndianCalendar", "HinduCalendar"], // ABCDE of Time
  "174bbca8-b31b-4569-9d9b-39aa162a9209": ["Timekeeping", "IndianCalendar", "HinduCalendar"], // Calendar Conundrum
  "017e3ca9-ff8d-49db-95ae-6ac9c7fa22ac": ["IndianMythology", "HinduMythology"], // Mahakaal as Nataraja
  "4f7f2648-1e4c-4843-981e-5b8c4fc03f48": ["IndianMythology", "HinduMythology"], // Kaalchakra
  "a46addb7-97e5-4fe0-981d-842721ae342e": ["IndianMythology", "HinduMythology", "IndianAstronomy", "IndianAstrology"], // Indian Astronomy and Astrology
  "c90ad6da-e31c-42b0-8ccd-baefe6f90835": ["Timekeeping", "IndianCalendar", "HinduCalendar", "IndianMythology", "HinduMythology", "IndianAstronomy", "IndianAstrology"], // Days of the Divine
  "b3b3437b-bc0b-4996-b8cc-29053deaf5df": ["IndianMythology", "HinduMythology", "IndianAstronomy", "IndianAstrology"], // Seven Days, Seven Gods
  "cc81f4bd-3db3-4dba-a0dc-09d11205a58d": ["IndianAstronomy", "IndianAstrology"], // Navagrahas
  "319977ac-84b6-40e6-86a5-e089f392e7cd": ["IndianAstronomy", "IndianAstrology"], // Divine Directional Harmony
  "0dd99a04-7528-464e-9da2-fc782de0808c": ["IndianAstronomy", "IndianAstrology"], // Cosmic Pantheon
  "df653511-9288-4be9-b1e4-ad1cd3101331": ["AstrologyPerspective", "IndianAstronomy", "IndianAstrology"], // My Perspective on Astrology
  "f806f53f-4988-4f75-8b58-874ad01676c3": ["IndianMythology", "HinduMythology"], // Samudra Manthan
  "416b18a9-1758-4d32-bb5b-6b0943a3070c": ["IndianMythology", "HinduMythology", "IndianAstronomy", "IndianAstrology"], // Rahu and Ketu
  "c9fe28c5-5122-4fdb-988d-51ee228f1f28": ["IndianAstronomy", "IndianAstrology"], // The Rashis
  "2fce94b0-2c38-4445-a2f1-32f0e34c8598": ["IndianAstronomy", "IndianAstrology"], // Choosing Your Zodiac
  "1168ff9b-c823-4b00-b9a3-4f3e132745a0": ["Timekeeping", "IndianCalendar", "HinduCalendar", "IndianAstronomy", "IndianAstrology"], // Cosmic Timekeeping
  "bd521be1-d612-4999-ad23-aed820f88e51": ["IndianMythology", "HinduMythology", "IndianAstronomy", "IndianAstrology"], // Kundali
  "656d1610-5e79-4704-a27d-49bfa981108f": ["Timekeeping", "IndianCalendar", "HinduCalendar", "IndianAstronomy", "IndianAstrology"], // Tithi
  "62cb4927-27c6-47b6-b744-a3e8c1b7d295": ["Timekeeping", "IndianCalendar", "HinduCalendar", "IndianAstronomy", "IndianAstrology"], // Panchang
  "f7e9a18d-926d-4410-9ed2-f6c14ce6befc": ["IndianMythology", "HinduMythology"], // Mahakaleshwar
  "4e5f6g7h-8i9j-0k1l-2m3n-4o5p6q7r8s9t": ["IndianMythology", "HinduMythology"] // Kaal Bhairava (placeholder UUID)
};
