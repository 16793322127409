import React from 'react';
import { Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const LazyImage = ({ src, alt, ...props }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Image
        src={src}
        alt={alt}
        loading="lazy"
        decoding="async"
        {...props}
      />
    </motion.div>
  );
};

export default LazyImage;
