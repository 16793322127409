import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title, 
  description, 
  image = '/images/logo.png',
  type = 'website',
  publishedTime,
  modifiedTime,
  author = 'Namaskar India',
  keywords = 'indian history, mythology, podcast, storytelling, culture, heritage',
  episodeData
}) => {
  const siteUrl = 'https://namaskarindia.live';
  const fullTitle = `${title} | Namaskar India`;

  // Base schema
  const baseSchema = {
    '@context': 'https://schema.org',
    '@type': type === 'article' ? 'Article' : 'WebSite',
    url: siteUrl,
    name: title,
    description,
    image: `${siteUrl}${image}`,
    author: {
      '@type': 'Person',
      name: author
    },
    publisher: {
      '@type': 'Organization',
      name: 'Namaskar India',
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}/logo.png`
      }
    }
  };

  // Podcast episode schema
  const episodeSchema = episodeData ? {
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    name: episodeData.title,
    description: episodeData.description,
    datePublished: episodeData.publishDate,
    duration: episodeData.duration,
    url: episodeData.url,
    partOfSeries: {
      '@type': 'PodcastSeries',
      name: 'Namaskar India',
      url: siteUrl
    }
  } : null;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />

      {/* OpenGraph Meta Tags */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      <meta property="og:site_name" content="Namaskar India" />
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />
      <meta name="twitter:site" content="@NamaskarIndia" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(baseSchema)}
      </script>
      {episodeSchema && (
        <script type="application/ld+json">
          {JSON.stringify(episodeSchema)}
        </script>
      )}

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <link rel="canonical" href={siteUrl} />
    </Helmet>
  );
};

export default SEO;
