import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  HStack,
  Text,
  VStack,
  Tooltip,
  Progress,
  Image,
  Flex,
  useMediaQuery,
  Button,
  Badge,
} from '@chakra-ui/react';
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaForward,
  FaBackward,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

const AudioPlayer = ({ 
  title, 
  audioUrl, 
  imageUrl, 
  duration,
  pubDate,
  onEnded 
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 48em)');

  const audioRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const startTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      if (audioRef.current?.ended) {
        setIsPlaying(false);
        onEnded?.();
      } else {
        setCurrentTime(audioRef.current?.currentTime || 0);
      }
    }, 1000);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const onLoadedMetadata = () => {
    setIsLoading(false);
  };

  const onTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime);
  };

  const onSeek = (value) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const skipTime = (seconds) => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(
        Math.max(currentTime + seconds, 0),
        duration
      );
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <Box
      bg="gray.800"
      borderRadius="xl"
      p={4}
      borderWidth="1px"
      borderColor="gray.700"
      w="full"
      position="relative"
      _hover={{
        borderColor: 'brand.500',
        transform: 'translateY(-2px)',
        shadow: 'lg'
      }}
      transition="all 0.2s"
    >
      <audio
        ref={audioRef}
        src={audioUrl}
        onPlay={() => {
          setIsPlaying(true);
          startTimer();
        }}
        onPause={() => {
          setIsPlaying(false);
          clearInterval(intervalRef.current);
        }}
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
        preload="metadata"
      />

      <VStack spacing={4} align="stretch">
        <Flex gap={4}>
          {imageUrl && (
            <Image
              src={imageUrl}
              alt={title}
              boxSize={{ base: '80px', md: '120px' }}
              objectFit="cover"
              borderRadius="lg"
              flexShrink={0}
            />
          )}
          <VStack flex="1" align="start" spacing={2}>
            <Text
              fontSize={{ base: 'md', md: 'lg' }}
              fontWeight="bold"
              color="whiteAlpha.900"
              noOfLines={2}
            >
              {title}
            </Text>
            <HStack spacing={3} flexWrap="wrap">
              <Badge colorScheme="gray">
                Released: {formatDate(pubDate)}
              </Badge>
              <Badge colorScheme="brand">
                Episode Length: {formatTime(duration)}
              </Badge>
            </HStack>

            <HStack spacing={4} width="100%">
              <IconButton
                icon={<FaBackward />}
                aria-label="Skip backward"
                variant="ghost"
                colorScheme="whiteAlpha"
                onClick={() => skipTime(-10)}
                size={{ base: 'sm', md: 'md' }}
              />
              <IconButton
                icon={isPlaying ? <FaPause /> : <FaPlay />}
                aria-label={isPlaying ? 'Pause' : 'Play'}
                onClick={togglePlay}
                colorScheme="brand"
                size={{ base: 'md', md: 'lg' }}
                isRound
              />
              <IconButton
                icon={<FaForward />}
                aria-label="Skip forward"
                variant="ghost"
                colorScheme="whiteAlpha"
                onClick={() => skipTime(10)}
                size={{ base: 'sm', md: 'md' }}
              />
              <Box position="relative" onMouseLeave={() => setShowVolumeSlider(false)}>
                <IconButton
                  icon={isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                  aria-label={isMuted ? 'Unmute' : 'Mute'}
                  variant="ghost"
                  colorScheme="whiteAlpha"
                  onClick={toggleMute}
                  onMouseEnter={() => setShowVolumeSlider(true)}
                  size={{ base: 'sm', md: 'md' }}
                />
                {showVolumeSlider && !isMobile && (
                  <Box
                    position="absolute"
                    bottom="100%"
                    left="50%"
                    transform="translateX(-50%)"
                    p={2}
                    bg="gray.700"
                    borderRadius="md"
                    width="100px"
                    zIndex={2}
                  >
                    <Slider
                      aria-label="Volume"
                      defaultValue={volume}
                      min={0}
                      max={1}
                      step={0.1}
                      orientation="vertical"
                      minH="100px"
                      onChange={(v) => setVolume(v)}
                    >
                      <SliderTrack bg="gray.600">
                        <SliderFilledTrack bg="brand.500" />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                )}
              </Box>
            </HStack>
          </VStack>
        </Flex>

        {isLoading ? (
          <Progress size="xs" isIndeterminate />
        ) : (
          <HStack w="full" spacing={4}>
            <Text fontSize="sm" color="whiteAlpha.700" minW="45px">
              {formatTime(currentTime)}
            </Text>
            <Slider
              aria-label="Seek"
              value={currentTime}
              min={0}
              max={duration}
              onChange={onSeek}
              focusThumbOnChange={false}
            >
              <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="brand.500" />
              </SliderTrack>
              <Tooltip
                label={formatTime(currentTime)}
                placement="top"
                isOpen={false}
                bg="gray.700"
              >
                <SliderThumb boxSize={3} />
              </Tooltip>
            </Slider>
            <Text fontSize="sm" color="whiteAlpha.700" minW="45px">
              {formatTime(duration)}
            </Text>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default AudioPlayer;
