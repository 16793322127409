import React, { useState, useEffect } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <IconButton
          aria-label="Scroll to top"
          icon={<FaArrowUp />}
          position="fixed"
          bottom="4"
          right="4"
          colorScheme="brand"
          size="lg"
          onClick={scrollToTop}
          opacity={0.8}
          _hover={{ opacity: 1 }}
          borderRadius="full"
          boxShadow="lg"
        />
      )}
    </>
  );
};

export default ScrollToTop;
