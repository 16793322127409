import React, { useState, useMemo } from 'react';
import {
  Box,
  Container,
  VStack,
  SimpleGrid,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Tag,
  TagLabel,
  HStack,
  Link,
  Image,
  Avatar,
  Wrap,
  WrapItem,
  Badge,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaSearch, FaTwitter, FaGlobe, FaInstagram, FaArrowRight, FaUser, FaLinkedin, FaFacebook, FaYoutube, FaMedium, FaPlay } from 'react-icons/fa';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';
import ScrollReveal from '../components/ScrollReveal';
import guestsData from '../data/guests.json';

const GuestCard = ({ guest }) => (
  <ScrollReveal>
    <Box
      bg="gray.800"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.700"
      overflow="hidden"
      _hover={{
        borderColor: 'brand.500',
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)'
      }}
      transition="all 0.3s ease-in-out"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={{ base: 4, md: 6 }} flex="1" display="flex" flexDirection="column">
        <VStack spacing={{ base: 4, md: 6 }} align="stretch" flex="1">
          {/* Header */}
          <Box flexShrink={0}>
            <HStack spacing={4} alignItems="flex-start">
              <Avatar
                src={guest.image}
                name={guest.name}
                size="xl"
                bg="brand.500"
              />
              <Box flex="1">
                <Heading
                  as="h3"
                  size="md"
                  color="whiteAlpha.900"
                  mb={1}
                >
                  {guest.name}
                </Heading>
                <Text
                  fontSize="sm"
                  color="brand.400"
                  fontWeight="medium"
                >
                  {guest.title}
                </Text>
              </Box>
            </HStack>
          </Box>

          {/* Bio */}
          <Text
            color="whiteAlpha.800"
            fontSize="md"
            flex="1"
          >
            {guest.bio}
          </Text>

          {/* Episodes */}
          <Box>
            <Text
              color="whiteAlpha.700"
              fontSize="sm"
              fontWeight="medium"
              mb={2}
            >
              Featured Episodes
            </Text>
            <VStack align="stretch" spacing={2}>
              {guest.episodes.map((episode, index) => (
                <RouterLink
                  key={index}
                  to={`/episodes?search=${encodeURIComponent(episode.title)}`}
                >
                  <HStack
                    spacing={3}
                    p={2}
                    borderRadius="md"
                    bg="gray.700"
                    _hover={{
                      bg: 'gray.600',
                      transform: 'translateX(4px)'
                    }}
                    transition="all 0.2s"
                  >
                    <Icon as={FaPlay} color="brand.500" />
                    <Text color="whiteAlpha.900" fontSize="sm" flex="1">
                      {episode.title}
                    </Text>
                    {episode.parts > 1 && (
                      <Badge colorScheme="orange">
                        {episode.parts} parts
                      </Badge>
                    )}
                  </HStack>
                </RouterLink>
              ))}
            </VStack>
          </Box>

          {/* Expertise */}
          <Box>
            <Text
              color="whiteAlpha.700"
              fontSize="sm"
              fontWeight="medium"
              mb={2}
            >
              Areas of Expertise
            </Text>
            <Wrap spacing={2}>
              {guest.expertise.map((area, index) => (
                <WrapItem key={index}>
                  <RouterLink to={`/episodes?tag=${encodeURIComponent(area)}`}>
                    <Tag
                      size="md"
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="brand"
                      cursor="pointer"
                      _hover={{
                        opacity: 0.8,
                        transform: 'translateY(-1px)',
                      }}
                      transition="all 0.2s"
                    >
                      <TagLabel>{area}</TagLabel>
                    </Tag>
                  </RouterLink>
                </WrapItem>
              ))}
            </Wrap>
          </Box>

          {/* Social Links */}
          <Box>
            <Text
              color="whiteAlpha.700"
              fontSize="sm"
              fontWeight="medium"
              mb={2}
            >
              Connect
            </Text>
            <HStack spacing={4}>
              {guest.social.website && (
                <Link
                  href={guest.social.website}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaGlobe} boxSize={5} />
                </Link>
              )}
              {guest.social.linkedin && (
                <Link
                  href={guest.social.linkedin}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaLinkedin} boxSize={5} />
                </Link>
              )}
              {guest.social.twitter && (
                <Link
                  href={guest.social.twitter}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaTwitter} boxSize={5} />
                </Link>
              )}
              {guest.social.instagram && (
                <Link
                  href={guest.social.instagram}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaInstagram} boxSize={5} />
                </Link>
              )}
              {guest.social.facebook && (
                <Link
                  href={guest.social.facebook}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaFacebook} boxSize={5} />
                </Link>
              )}
              {guest.social.youtube && (
                <Link
                  href={guest.social.youtube}
                  isExternal
                  color="whiteAlpha.700"
                  _hover={{ color: 'brand.300' }}
                >
                  <Icon as={FaYoutube} boxSize={5} />
                </Link>
              )}
            </HStack>
          </Box>
        </VStack>
      </Box>
    </Box>
  </ScrollReveal>
);

const Guests = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredGuests = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return guestsData.guests.filter(guest =>
      guest.name.toLowerCase().includes(query) ||
      guest.title.toLowerCase().includes(query) ||
      guest.bio.toLowerCase().includes(query) ||
      guest.expertise.some(area => area.toLowerCase().includes(query))
    );
  }, [searchQuery]);

  return (
    <PageTransition>
      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <SEO
          title="Our Guests"
          description="Meet our distinguished guests who bring their expertise and insights to the Indian History Podcast."
          keywords="podcast guests, experts, historians, storytellers, indian history"
        />

        <Container maxW="container.xl">
          <VStack spacing={12} align="stretch">
            {/* Header */}
            <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                lineHeight="1.2"
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                mb={{ base: 2, md: 4 }}
                fontWeight="bold"
              >
                Our Distinguished Guests
              </Heading>

              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                color="whiteAlpha.900" 
                maxW="2xl"
                px={{ base: 4, md: 0 }}
              >
                Meet the experts and thought leaders who bring their unique perspectives to our podcast.
              </Text>
            </VStack>

            {/* Search Bar */}
            <Box maxW="2xl" mx="auto" w="full" px={4}>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaSearch} color="whiteAlpha.400" />
                </InputLeftElement>
                <Input
                  placeholder="Search guests..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  bg="gray.800"
                  border="1px solid"
                  borderColor="gray.700"
                  _hover={{
                    borderColor: 'gray.600'
                  }}
                  _focus={{
                    borderColor: 'brand.500',
                    boxShadow: 'none',
                    bg: 'gray.750'
                  }}
                  color="whiteAlpha.900"
                  _placeholder={{
                    color: 'whiteAlpha.400'
                  }}
                />
              </InputGroup>
            </Box>

            {/* Guest Cards */}
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: 6, md: 8 }}
              px={{ base: 4, md: 0 }}
            >
              {filteredGuests.map((guest) => (
                <GuestCard key={guest.id} guest={guest} />
              ))}
            </SimpleGrid>

            {/* No Results */}
            {filteredGuests.length === 0 && (
              <VStack spacing={4} py={8} textAlign="center">
                <Text fontSize="lg" color="whiteAlpha.900">
                  No guests found matching "{searchQuery}"
                </Text>
                <Text fontSize="md" color="whiteAlpha.600">
                  Try adjusting your search terms or browse all guests above.
                </Text>
              </VStack>
            )}
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default Guests;
