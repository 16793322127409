import React, { useState, useEffect } from 'react';
import { Text, VStack, Fade, Box } from '@chakra-ui/react';

const quotes = [
  {
    sanskrit: "यथा कामो भवेत्तथा वशमापद्यते। यथा वशं तथा कर्म। यथा कर्म तथा गतिः।",
    text: "You are what your deep, driving desire is. As your desire is, so is your will. As your will is, so is your deed. As your deed is, so is your destiny.",
    author: "Brihadaranyaka Upanishad"
  },
  {
    sanskrit: "असतो मा सद्गमय। तमसो मा ज्योतिर्गमय। मृत्योर्मामृतं गमय।",
    text: "From ignorance, lead me to truth; From darkness, lead me to light; From death, lead me to immortality.",
    author: "Brihadaranyaka Upanishad"
  },
  {
    sanskrit: "योगे युक्तो यदा चित्तं वायौ निवातस्थिते दीप इव।",
    text: "When meditation is mastered, the mind is unwavering like the flame of a lamp in a windless place.",
    author: "Bhagavad Gita"
  },
  {
    sanskrit: "यद् दृष्टम् अविज्ञातं यद् विज्ञातं अविद्यते। तद्वा अस्य तत्वम् अस्मि नित्यमुक्तस्य निर्मलत्वात्।",
    text: "That which cannot be seen but is the witness of all visions; That which cannot be heard but is the hearer of all sounds; That which cannot be thought but is the thinker behind all thoughts. That is your true Self; free from the false identities of the material world.",
    author: "Kenopanishad"
  },
  {
    sanskrit: "वसुधैव कुटुम्बकम्।",
    text: "The whole world is one family.",
    author: "Maha Upanishad"
  },
  {
    sanskrit: "यः कर्मणि विज्ञानं च समन्वारब्धे स पश्यति।",
    text: "The wise sees knowledge and action as one; they see truly.",
    author: "Bhagavad Gita"
  },
  {
    sanskrit: "यस्मिन् सर्वाणि भूतानि आत्मैवाभूद् विजानतः। तत्र को मोहः कः शोकः एकत्वमनुपश्यतः।",
    text: "He who sees all beings in his own self, and his own self in all beings, loses all fear.",
    author: "Isha Upanishad"
  },
  {
    sanskrit: "सत्यमेव जयते नानृतम्। सत्येन पन्था विततो देवयानः। येनाक्रमन्ति ऋषयो ह्याप्तकामा। यत्र तत् सत्यस्य परमं निधानम्।",
    text: "Truth alone triumphs; not falsehood. Through truth, the divine path is spread out by which the sages whose desires have been completely fulfilled, reach where that supreme treasure of Truth resides.",
    author: "Mundaka Upanishad"
  },
  {
    sanskrit: "न कर्मणा न प्रजया धनेन त्यागेनैके अमृतत्वमानशुः।",
    text: "Not by works, not by progeny, not by wealth, but by renunciation alone is immortality achieved.",
    author: "Brihadaranyaka Upanishad"
  },
  {
    sanskrit: "नायमात्मा प्रवचनेन लभ्यो न मेधया न बहुना श्रुतेन। यमेवैष वृणुते तेन लभ्यः। तस्यैष आत्मा विवृणुते तनूं स्वाम्।",
    text: "This Self cannot be attained by study, nor by intellect, nor by much hearing. Whomsoever the Self chooses, by him alone is it attained. To him, this Self reveals its own nature.",
    author: "Katha Upanishad"
  }
];

const QuoteCarousel = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setIsVisible(true);
      }, 500);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box 
      minH="250px" 
      display="flex" 
      alignItems="center"
      width="100%"
    >
      <Fade 
        in={isVisible} 
        transition={{ enter: { duration: 0.8 }, exit: { duration: 0.5 } }}
        style={{ width: '100%' }}
      >
        <VStack 
          spacing={4} 
          textAlign="center" 
          width="100%"
          px={4}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            fontFamily="Sanskrit2003"
            color="brand.300"
            letterSpacing="wide"
            fontWeight="medium"
            textShadow="0 1px 2px rgba(0,0,0,0.2)"
          >
            {quotes[currentQuoteIndex].sanskrit}
          </Text>
          <Text 
            fontSize={{ base: "md", md: "lg" }} 
            fontStyle="italic"
            color="whiteAlpha.900"
            lineHeight="tall"
            maxW="3xl"
            fontWeight="medium"
          >
            "{quotes[currentQuoteIndex].text}"
          </Text>
          <Text 
            fontSize={{ base: "sm", md: "md" }} 
            color="whiteAlpha.600"
            fontWeight="medium"
            letterSpacing="wider"
            mt={2}
          >
            — {quotes[currentQuoteIndex].author}
          </Text>
        </VStack>
      </Fade>
    </Box>
  );
};

export default QuoteCarousel;
