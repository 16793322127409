import React from 'react';
import Layout from './components/Layout';
import AppRoutes from './AppRoutes';
import RouteScrollToTop from './components/RouteScrollToTop';

const App = () => {
  return (
    <Layout>
      <RouteScrollToTop />
      <AppRoutes />
    </Layout>
  );
};

export default App;
