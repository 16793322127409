import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FaExclamationTriangle } from 'react-icons/fa';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box bg="gray.900" minH="100vh" py={20}>
          <Container maxW="container.md">
            <VStack spacing={8} align="center" textAlign="center">
              <Icon as={FaExclamationTriangle} boxSize={12} color="red.500" />
              <VStack spacing={4}>
                <Heading
                  as="h1"
                  fontSize={{ base: "3xl", md: "4xl" }}
                  bgGradient="linear(to-r, brand.500, purple.500)"
                  bgClip="text"
                  fontWeight="bold"
                >
                  Something went wrong
                </Heading>
                <Text 
                  fontSize={{ base: "lg", md: "xl" }}
                  color="whiteAlpha.800"
                  maxW="xl"
                >
                  We're sorry, but something unexpected happened. Please try refreshing the page.
                </Text>
              </VStack>
              <Button
                colorScheme="brand"
                size="lg"
                onClick={() => window.location.reload()}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg'
                }}
                transition="all 0.2s"
              >
                Refresh Page
              </Button>
            </VStack>
          </Container>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
