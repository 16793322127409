import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { FaShieldAlt } from 'react-icons/fa';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';

const Privacy = () => {
  return (
    <PageTransition>
      <SEO
        title="Privacy Policy - Namaskar India"
        description="Privacy policy for Namaskar India podcast."
      />

      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <Container maxW="container.xl">
          <VStack spacing={12} align="stretch">
            {/* Header */}
            <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                lineHeight="1.2"
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                mb={{ base: 2, md: 4 }}
                fontWeight="bold"
              >
                Privacy Policy
              </Heading>
            </VStack>

            {/* Content */}
            <VStack spacing={8} align="stretch" maxW="2xl" mx="auto">
              <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                At Namaskar India, we respect your privacy and are committed to protecting it. This policy outlines our practices regarding information collection and usage.
              </Text>

              <List spacing={6}>
                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaShieldAlt} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    We use standard podcast analytics to understand how our content is being consumed. This includes basic information like play counts and download statistics. We do not collect any personally identifiable information through our podcast feeds.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaShieldAlt} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    If you choose to join our community groups or interact with us on social media, we only use this interaction for communication within those platforms. We never share your information with third parties.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaShieldAlt} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    Our website uses cookies for basic functionality and analytics. These help us improve your experience but do not track personal information.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaShieldAlt} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    For any privacy concerns or questions about our data practices, please contact us through our social media channels or email.
                  </Text>
                </ListItem>
              </List>

              <Text color="whiteAlpha.600" fontSize="sm" textAlign="center" mt={8}>
                Last updated: March 2025
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default Privacy;
