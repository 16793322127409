import React from 'react';
import { Box, Tag, TagLabel, Wrap, WrapItem } from '@chakra-ui/react';
import tags from '../data/tags.json';

const colorMap = {
  'Ancient Indian History': 'orange',
  'Indian Mythology': 'blue',
  'Concept of Time': 'green'
};

const TagFilter = ({ selectedTags, onTagSelect }) => {
  return (
    <Box maxW="2xl" mx="auto" w="full">
      <Wrap spacing={2} mb={4}>
        {tags.map((tag) => (
          <WrapItem key={tag.id}>
            <Tag
              size="md"
              variant={selectedTags.includes(tag.id) ? 'solid' : 'subtle'}
              colorScheme={colorMap[tag.group]}
              cursor="pointer"
              onClick={() => onTagSelect(tag.id)}
              _hover={{
                opacity: 0.8,
                transform: 'translateY(-1px)',
              }}
              transition="all 0.2s"
            >
              <TagLabel>{tag.name}</TagLabel>
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
};

export default TagFilter;
