import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Text,
  VStack,
  Flex,
  Badge,
  IconButton,
  useDisclosure,
  Divider,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaInfoCircle } from 'react-icons/fa';
import AudioPlayer from './AudioPlayer';

const getCleanDescription = (html) => {
  // Get content between first <p> tags
  const firstParagraph = html.match(/<p>(.*?)<\/p>/)?.[1] || '';
  
  // Remove any remaining HTML tags
  const withoutTags = firstParagraph.replace(/<[^>]*>/g, '');
  
  // Clean up HTML entities
  return withoutTags
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/\n/g, ' ')
    .trim();
};

const EpisodeCard = ({ episode, duration, isLoading = false }) => {
  const { isOpen, onToggle } = useDisclosure();
  const cleanDescription = getCleanDescription(episode?.description || '');

  if (isLoading) {
    return (
      <VStack spacing={2} align="stretch">
        <Box
          bg="gray.800"
          p={4}
          borderRadius="lg"
          borderWidth="1px"
          borderColor="gray.700"
        >
          <Skeleton height="100px" mb={4} />
          <SkeletonText noOfLines={2} spacing={4} />
        </Box>
      </VStack>
    );
  }

  return (
    <VStack spacing={2} align="stretch">
      <AudioPlayer
        title={episode.title}
        audioUrl={episode.enclosure.url}
        imageUrl={episode.image}
        duration={duration}
        pubDate={episode.pubDate}
      />
      
      {cleanDescription && (
        <Box>
          <Button
            size="sm"
            variant="ghost"
            colorScheme="whiteAlpha"
            width="full"
            onClick={onToggle}
            leftIcon={<FaInfoCircle />}
            rightIcon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
            _hover={{
              bg: 'gray.700'
            }}
          >
            Episode Details
          </Button>

          <Collapse in={isOpen} animateOpacity>
            <Box
              mt={2}
              p={4}
              bg="gray.800"
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.700"
            >
              <Text
                fontSize="sm"
                color="whiteAlpha.900"
                lineHeight="tall"
              >
                {cleanDescription}
              </Text>
            </Box>
          </Collapse>
        </Box>
      )}
    </VStack>
  );
};

export default EpisodeCard;
