import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  Text, 
  VStack, 
  SimpleGrid, 
  HStack, 
  Icon, 
  Link, 
  Button, 
  List, 
  ListItem, 
  ListIcon 
} from '@chakra-ui/react';
import { 
  FaLinkedin, 
  FaTwitter, 
  FaInstagram, 
  FaSpotify, 
  FaPlay, 
  FaCheckCircle, 
  FaUserFriends, 
  FaArrowRight 
} from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';
import TeamMember from '../components/TeamMember';

const About = () => {
  return (
    <PageTransition>
      <SEO
        title="About Us - Namaskar India"
        description="Meet the team behind Namaskar India podcast, bringing you stories from Indian history and mythology."
      />

      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <Container maxW="container.xl">
          <VStack spacing={16}>
            {/* Hero Section */}
            <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                lineHeight="1.2"
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                mb={{ base: 2, md: 4 }}
                fontWeight="bold"
              >
                About Namaskar India
              </Heading>

              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                color="whiteAlpha.900" 
                maxW="2xl"
                px={{ base: 4, md: 0 }}
              >
                Discover the stories behind India's rich history and cultural heritage through engaging storytelling.
              </Text>
            </VStack>

            {/* Team Section */}
            <VStack spacing={8}>
              <Heading
                as="h2"
                fontSize={{ base: "3xl", md: "4xl" }}
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                fontWeight="bold"
                textAlign="center"
              >
                Meet the Team
              </Heading>

              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} w="full">
                <TeamMember
                  name="Aradhana Duppala"
                  role="Host & Content Creator"
                  bio="An Engineering Manager by profession, Aradhana is a passionate history and mythology enthusiast who brings ancient tales to life through engaging storytelling. Her deep love for India's cultural heritage, combined with her technical background, allows her to present complex historical narratives in an accessible and engaging way for modern audiences."
                  image="/host-image.jpg"
                  social={{
                    linkedin: "https://www.linkedin.com/in/aradhanaduppala",
                    twitter: "https://twitter.com/AradhanaDuppala",
                    instagram: "https://www.instagram.com/aduppala"
                  }}
                />
                <TeamMember
                  name="Shivansh Singh"
                  role="Production Manager"
                  bio="A Principal Solutions Architect by profession, Shivansh brings his technical expertise to Namaskar India driven by his deep love for Indian culture and storytelling. His passion for preserving and sharing India's rich heritage, combined with his technical skills, ensures our stories reach listeners with professional quality and authentic presentation."
                  image="/images/team/shivansh.jpg"
                  social={{
                    linkedin: "https://www.linkedin.com/in/singhshivansh",
                    twitter: "https://twitter.com/shivansh_singh",
                    instagram: "https://www.instagram.com/shivansh"
                  }}
                />
              </SimpleGrid>
            </VStack>

            {/* Mission Section */}
            <Box 
              w="full" 
              bg="gray.800" 
              borderRadius="xl" 
              p={12}
              borderWidth="1px"
              borderColor="gray.700"
              maxW="4xl"
              mx="auto"
            >
              <VStack spacing={6} textAlign="center">
                <Heading
                  as="h2"
                  fontSize={{ base: "3xl", md: "4xl" }}
                  bgGradient="linear(to-r, brand.500, purple.500)"
                  bgClip="text"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Our Mission
                </Heading>
                <Text fontSize="lg" color="whiteAlpha.900" lineHeight="tall">
                  At Namaskar India, we're passionate about making Indian history and mythology 
                  accessible to everyone. Through carefully researched episodes and engaging 
                  storytelling, we aim to preserve and share the rich cultural heritage of India 
                  with audiences worldwide.
                </Text>
                <Text fontSize="lg" color="whiteAlpha.900" lineHeight="tall">
                  Our goal is to bridge the past with the present, helping listeners understand 
                  the depth and relevance of India's ancient wisdom in today's world.
                </Text>
              </VStack>
            </Box>

            {/* What We Do Section */}
            <VStack spacing={8}>
              <Heading
                as="h2"
                fontSize={{ base: "3xl", md: "4xl" }}
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                fontWeight="bold"
                textAlign="center"
              >
                What We Do
              </Heading>
              <VStack spacing={12} maxW="4xl" mx="auto">
                <VStack spacing={4} textAlign="center">
                  <Text fontSize="lg" color="whiteAlpha.900">
                    Through carefully researched episodes, we explore:
                  </Text>
                </VStack>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                  <VStack
                    align="start"
                    spacing={4}
                    bg="gray.800"
                    p={6}
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor="gray.700"
                  >
                    <Heading size="md" color="brand.300">Historical Series</Heading>
                    <List spacing={3} color="whiteAlpha.900">
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Ancient Indian civilizations and empires
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Cultural evolution and traditions
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Significant historical events
                      </ListItem>
                    </List>
                  </VStack>

                  <VStack
                    align="start"
                    spacing={4}
                    bg="gray.800"
                    p={6}
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor="gray.700"
                  >
                    <Heading size="md" color="brand.300">Mythology Series</Heading>
                    <List spacing={3} color="whiteAlpha.900">
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Stories from ancient texts and epics
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Philosophical concepts and teachings
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="brand.500" />
                        Cultural impact and modern relevance
                      </ListItem>
                    </List>
                  </VStack>
                </SimpleGrid>
              </VStack>
            </VStack>

            {/* Listen Now Section */}
            <Box 
              w="full" 
              bg="gray.800" 
              borderRadius="xl" 
              p={8}
              borderWidth="1px"
              borderColor="gray.700"
            >
              <VStack spacing={6} align="center" textAlign="center">
                <Icon as={FaUserFriends} boxSize={12} color="brand.500" />
                <Heading
                  as="h2"
                  fontSize={{ base: "3xl", md: "4xl" }}
                  bgGradient="linear(to-r, brand.500, purple.500)"
                  bgClip="text"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Start Your Journey
                </Heading>
                <Text fontSize="lg" color="whiteAlpha.800" maxW="2xl">
                  Join us as we explore the fascinating stories that have shaped Indian culture and civilization.
                </Text>
                <HStack spacing={4} pt={4}>
                  <Button
                    as={RouterLink}
                    to="/episodes"
                    size="lg"
                    colorScheme="brand"
                    leftIcon={<FaPlay />}
                  >
                    Listen to Episodes
                  </Button>
                  <Button
                    as={RouterLink}
                    to="/contact"
                    variant="outline"
                    colorScheme="whiteAlpha"
                    size="lg"
                    _hover={{
                      transform: 'translateY(-2px)',
                      shadow: 'lg',
                      bg: 'whiteAlpha.100'
                    }}
                    transition="all 0.2s"
                  >
                    Contact Us
                  </Button>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default About;
