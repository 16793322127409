import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        bg: 'gray.900',
        color: 'white'
      }
    }
  },
  colors: {
    brand: {
      50: '#fff3e0',
      100: '#ffe0b2',
      200: '#ffcc80',
      300: '#ffb74d',
      400: '#ffa726',
      500: '#ff9800',
      600: '#fb8c00',
      700: '#f57c00',
      800: '#ef6c00',
      900: '#e65100',
    }
  },
  components: {
    Heading: {
      baseStyle: {
        color: 'white'
      },
      variants: {
        gradient: {
          bgGradient: 'linear(to-r, brand.500, purple.500)',
          bgClip: 'text'
        }
      }
    },
    Text: {
      baseStyle: {
        color: 'white'
      },
      variants: {
        secondary: {
          color: 'whiteAlpha.800'
        },
        muted: {
          color: 'whiteAlpha.700'
        },
        accent: {
          color: 'brand.300',
          fontWeight: 'bold'
        }
      }
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none'
        }
      },
      variants: {
        nav: {
          color: 'whiteAlpha.900',
          fontWeight: 'medium',
          _hover: {
            color: 'brand.300'
          },
          _active: {
            color: 'brand.300',
            fontWeight: 'bold'
          }
        }
      }
    },
    Button: {
      variants: {
        solid: {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }
        },
        outline: {
          borderColor: 'brand.500',
          color: 'brand.300',
          _hover: {
            bg: 'whiteAlpha.100',
            transform: 'translateY(-2px)',
          }
        },
        ghost: {
          color: 'whiteAlpha.900',
          _hover: {
            bg: 'whiteAlpha.100',
            color: 'brand.300'
          }
        }
      }
    },
    Card: {
      baseStyle: {
        container: {
          bg: 'gray.800',
          borderColor: 'gray.700'
        }
      }
    },
    Accordion: {
      baseStyle: {
        container: {
          bg: 'gray.800',
          borderColor: 'gray.700'
        },
        panel: {
          bg: 'gray.800',
          color: 'white'
        },
        button: {
          _hover: {
            bg: 'gray.700'
          },
          _expanded: {
            bg: 'gray.700',
            color: 'brand.300'
          }
        }
      }
    }
  }
})

export default theme
