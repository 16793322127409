import React, { useState, useEffect } from 'react'
import { Box, Flex, HStack, Link, Image, useDisclosure, IconButton, VStack, Button, Text } from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { FaSpotify } from 'react-icons/fa'

const NavLink = ({ to, children }) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      as={RouterLink}
      to={to}
      px={3}
      py={1}
      rounded="md"
      color={isActive ? 'brand.300' : 'whiteAlpha.900'}
      fontWeight={isActive ? 'bold' : 'medium'}
      position="relative"
      _hover={{
        textDecoration: 'none',
        color: 'brand.300',
        _after: {
          width: '100%',
        },
      }}
      _after={{
        content: '""',
        position: 'absolute',
        bottom: '-2px',
        left: '0',
        width: isActive ? '100%' : '0%',
        height: '2px',
        bg: 'brand.500',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      {children}
    </Link>
  )
}

const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      setScrolled(offset > 50)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box
      as="nav"
      position="sticky"
      top="0"
      zIndex="sticky"
      bg={scrolled ? 'rgba(23, 25, 35, 0.9)' : 'gray.900'}
      transition="all 0.3s ease-in-out"
      borderBottom="1px"
      borderColor={scrolled ? 'gray.700' : 'gray.800'}
      backdropFilter={scrolled ? 'blur(10px)' : 'none'}
    >
      <Flex
        minH="64px"
        py={{ base: 2 }}
        px={{ base: 4, md: 8 }}
        align="center"
        maxW="container.xl"
        mx="auto"
      >
        <Link as={RouterLink} to="/" _hover={{ textDecoration: 'none' }}>
          <HStack spacing={3}>
            <Box
              position="relative"
              borderRadius="full"
              bg="gray.800"
              w="40px"
              h="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              transition="transform 0.2s"
              _hover={{ transform: 'scale(1.05)' }}
            >
              <Image 
                src="/images/logo.png" 
                alt="Namaskar India Logo"
                w="36px"
                h="36px"
                objectFit="contain"
              />
            </Box>
            <Text
              display={{ base: 'none', sm: 'block' }}
              bgGradient="linear(to-r, brand.500, purple.500)"
              bgClip="text"
              fontSize="xl"
              fontWeight="bold"
              letterSpacing="tight"
            >
              Namaskar India
            </Text>
          </HStack>
        </Link>

        <HStack spacing={8} ml="auto" display={{ base: 'none', md: 'flex' }}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/episodes">Episodes</NavLink>
          <NavLink to="/guests">Guests</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <Button
            as="a"
            href="https://open.spotify.com/show/03UrDnDHONZ92oOpWzfhxx"
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="brand"
            size="sm"
            leftIcon={<FaSpotify />}
            _hover={{
              transform: 'translateY(-2px)',
              shadow: 'lg'
            }}
            transition="all 0.2s"
          >
            Listen on Spotify
          </Button>
        </HStack>

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onToggle}
          icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
          variant="ghost"
          color="whiteAlpha.900"
          aria-label="Toggle Navigation"
          ml={4}
        />
      </Flex>

      {/* Mobile menu */}
      <VStack
        pos="fixed"
        top={0}
        left={0}
        right={0}
        display={{ base: isOpen ? 'flex' : 'none', md: 'none' }}
        flexDirection="column"
        p={8}
        pb={4}
        m={0}
        bg="gray.900"
        spacing={4}
        rounded="sm"
        shadow="sm"
        zIndex={20}
        borderBottom="1px solid"
        borderColor="gray.700"
      >
        <Flex justify="flex-end" w="100%" mb={8}>
          <IconButton
            aria-label="Close menu"
            icon={<CloseIcon />}
            onClick={onClose}
          />
        </Flex>
        <NavLink to="/" onClick={onClose}>Home</NavLink>
        <NavLink to="/episodes" onClick={onClose}>Episodes</NavLink>
        <NavLink to="/guests" onClick={onClose}>Guests</NavLink>
        <NavLink to="/about" onClick={onClose}>About</NavLink>
        <NavLink to="/contact" onClick={onClose}>Contact</NavLink>
        <Button
          as="a"
          href="https://open.spotify.com/show/03UrDnDHONZ92oOpWzfhxx"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="brand"
          size="sm"
          leftIcon={<FaSpotify />}
          w="full"
        >
          Listen on Spotify
        </Button>
      </VStack>
    </Box>
  )
}

export default Navbar
