import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { FaGavel } from 'react-icons/fa';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';

const Terms = () => {
  return (
    <PageTransition>
      <SEO
        title="Terms of Use - Namaskar India"
        description="Terms of use for Namaskar India podcast."
      />

      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <Container maxW="container.xl">
          <VStack spacing={12} align="stretch">
            {/* Header */}
            <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                lineHeight="1.2"
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                mb={{ base: 2, md: 4 }}
                fontWeight="bold"
              >
                Terms of Use
              </Heading>
            </VStack>

            {/* Content */}
            <VStack spacing={8} align="stretch" maxW="2xl" mx="auto">
              <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                By accessing and using Namaskar India podcast content and website, you agree to these terms of use. Please read them carefully.
              </Text>

              <List spacing={6}>
                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaGavel} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    Our content is for personal, non-commercial use only. You may not redistribute, sell, or use our content for commercial purposes without explicit written permission.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaGavel} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    All podcast content is protected by copyright. You may share links to episodes and quote brief excerpts with proper attribution to Namaskar India.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaGavel} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    While we strive for accuracy in our historical storytelling, we make no guarantees about the completeness or accuracy of the information presented. Historical interpretations may vary.
                  </Text>
                </ListItem>

                <ListItem display="flex" alignItems="flex-start">
                  <ListIcon as={FaGavel} color="brand.500" mt={1} />
                  <Text color="whiteAlpha.900" fontSize="lg" lineHeight="tall">
                    We reserve the right to modify these terms at any time. Your continued use of our content constitutes acceptance of any changes.
                  </Text>
                </ListItem>
              </List>

              <Text color="whiteAlpha.600" fontSize="sm" textAlign="center" mt={8}>
                Last updated: March 2025
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default Terms;
