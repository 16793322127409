import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

const Layout = ({ children }) => {
  return (
    <Box 
      minH="100vh" 
      display="flex" 
      flexDirection="column"
      bg="gray.900"
    >
      <ScrollToTop />
      <Navbar />
      <Box 
        flex="1" 
        width="100%"
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
