import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHistory, FaOm, FaBookReader, FaPlay } from 'react-icons/fa';
import SEO from '../components/SEO';
import StatsCard from '../components/StatsCard';
import QuoteCarousel from '../components/QuoteCarousel';
import ScrollReveal from '../components/ScrollReveal';
import LazyImage from '../components/LazyImage';
import PageTransition from '../components/PageTransition';
import episodesData from '../data/episodes.json';

const Home = () => {
  return (
    <PageTransition>
      <SEO
        title="Journey Through Indian History and Mythology"
        description="Explore the fascinating stories of Indian history and mythology through our engaging podcast series."
        keywords="indian history podcast, mythology stories, cultural heritage, storytelling, ancient india"
      />

      {/* Hero Section */}
      <Box
        position="relative"
        minH={{ base: "90vh", md: "calc(100vh - 64px)" }}
        display="flex"
        alignItems="center"
        overflow="hidden"
        bg="gray.900"
      >
        {/* Hero Background */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <LazyImage
            src="/images/hero-bg.jpg"
            alt="Hero Background"
            objectFit="cover"
            w="100%"
            h="100%"
            opacity={0.6}
            filter="auto"
            brightness="0.9"
          />
        </Box>

        {/* Gradient Overlay */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(0deg, var(--chakra-colors-gray-900) 0%, rgba(23,25,35,0.4) 100%)"
          zIndex={1}
        />

        {/* Content */}
        <Container maxW="container.xl" position="relative" zIndex={2}>
          <VStack spacing={{ base: 6, md: 8 }} align="center" textAlign="center">
            <ScrollReveal>
              <VStack spacing={4} maxW="3xl">
                <Heading
                  as="h1"
                  fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                  lineHeight="1.2"
                  bgGradient="linear(to-r, brand.500, purple.500)"
                  bgClip="text"
                  mb={{ base: 2, md: 4 }}
                  fontWeight="bold"
                >
                  Journey Through Indian History and Mythology
                </Heading>

                <Text 
                  fontSize={{ base: "lg", md: "xl" }} 
                  color="whiteAlpha.900" 
                  maxW="2xl"
                  px={{ base: 4, md: 0 }}
                >
                  Explore fascinating stories from India's rich past, from ancient civilizations 
                  to timeless myths, all through engaging storytelling.
                </Text>
              </VStack>
            </ScrollReveal>

            <ScrollReveal delay={0.2}>
              <HStack spacing={4}>
                <Button
                  as={RouterLink}
                  to="/episodes"
                  colorScheme="brand"
                  size="lg"
                  leftIcon={<Icon as={FaPlay} />}
                  _hover={{
                    transform: 'translateY(-2px)',
                    shadow: 'lg',
                  }}
                  transition="all 0.2s"
                >
                  Start Listening
                </Button>
                <Button
                  as={RouterLink}
                  to="/about"
                  variant="outline"
                  colorScheme="whiteAlpha"
                  size="lg"
                  _hover={{
                    transform: 'translateY(-2px)',
                    shadow: 'lg',
                    bg: 'whiteAlpha.100'
                  }}
                  transition="all 0.2s"
                >
                  Learn More
                </Button>
              </HStack>
            </ScrollReveal>

            <ScrollReveal delay={0.4}>
              <Box pt={8} maxW="4xl" mx="auto">
                <QuoteCarousel />
              </Box>
            </ScrollReveal>
          </VStack>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box as="section" py={{ base: 12, md: 20 }}>
        <Container maxW="container.xl">
          <VStack spacing={{ base: 8, md: 12 }} align="stretch">
            <Heading
              as="h2"
              fontSize={{ base: "3xl", md: "4xl" }}
              textAlign="center"
              variant="gradient"
            >
              Our Journey So Far
            </Heading>
            <StatsCard stats={episodesData.stats} />
          </VStack>
        </Container>
      </Box>

      {/* Features Section */}
      <Box 
        bg="gray.900" 
        py={{ base: 16, md: 20 }}
        borderTop="1px"
        borderColor="gray.800"
      >
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <Heading
              as="h2"
              fontSize={{ base: "2xl", md: "3xl" }}
              bgGradient="linear(to-r, brand.500, purple.500)"
              bgClip="text"
              fontWeight="bold"
              textAlign="center"
            >
              Why Listen to Namaskar India?
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} width="100%">
              <ScrollReveal direction="up" delay={0.2}>
                <Box
                  p={6}
                  bg="gray.800"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.700"
                  height="100%"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'xl',
                    borderColor: 'brand.500',
                  }}
                  transition="all 0.3s"
                >
                  <VStack spacing={4} align="flex-start" height="100%" justify="flex-start">
                    <Icon as={FaHistory} boxSize={8} color="brand.500" flexShrink={0} />
                    <Text fontSize="xl" fontWeight="bold" color="whiteAlpha.900" flexShrink={0}>
                      Well-Researched Stories
                    </Text>
                    <Text color="whiteAlpha.800" flexGrow={1}>
                      Carefully researched and thoughtfully presented historical narratives that bring the past to life.
                    </Text>
                  </VStack>
                </Box>
              </ScrollReveal>

              <ScrollReveal direction="up" delay={0.4}>
                <Box
                  p={6}
                  bg="gray.800"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.700"
                  height="100%"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'xl',
                    borderColor: 'brand.500',
                  }}
                  transition="all 0.3s"
                >
                  <VStack spacing={4} align="flex-start" height="100%" justify="flex-start">
                    <Icon as={FaOm} boxSize={8} color="brand.500" flexShrink={0} />
                    <Text fontSize="xl" fontWeight="bold" color="whiteAlpha.900" flexShrink={0}>
                      Cultural Insights
                    </Text>
                    <Text color="whiteAlpha.800" flexGrow={1}>
                      Deep dive into traditions, customs, and philosophical aspects of Indian culture.
                    </Text>
                  </VStack>
                </Box>
              </ScrollReveal>

              <ScrollReveal direction="up" delay={0.6}>
                <Box
                  p={6}
                  bg="gray.800"
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.700"
                  height="100%"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'xl',
                    borderColor: 'brand.500',
                  }}
                  transition="all 0.3s"
                >
                  <VStack spacing={4} align="flex-start" height="100%" justify="flex-start">
                    <Icon as={FaBookReader} boxSize={8} color="brand.500" flexShrink={0} />
                    <Text fontSize="xl" fontWeight="bold" color="whiteAlpha.900" flexShrink={0}>
                      Engaging Storytelling
                    </Text>
                    <Text color="whiteAlpha.800" flexGrow={1}>
                      Captivating narratives that bring ancient tales to life for modern audiences.
                    </Text>
                  </VStack>
                </Box>
              </ScrollReveal>
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default Home;
