import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Container,
  VStack,
  Text,
  Badge,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  Collapse,
  Spinner,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { FaSearch, FaChevronDown, FaExclamationTriangle } from 'react-icons/fa';
import { useSearchParams, useLocation } from 'react-router-dom';
import SEO from '../components/SEO';
import EpisodeCard from '../components/EpisodeCard';
import TagFilter from '../components/TagFilter';
import episodesData from '../data/episodes.json';
import { episodeTags } from '../data/episodeTags';
import lastUpdated from '../data/lastUpdated.json';
import tags from '../data/tags.json';

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date unavailable';
  }
};

const parseDuration = (durationStr) => {
  try {
    if (!durationStr || typeof durationStr !== 'string') {
      throw new Error('Invalid duration string');
    }
    const parts = durationStr.split(':');
    if (parts.length === 2) {
      return parseInt(parts[0]) * 60 + parseInt(parts[1]);
    }
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
  } catch (error) {
    console.error('Error parsing duration:', error);
    return 0;
  }
};

const Episodes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('search') || '');
  const [selectedTags, setSelectedTags] = useState([]);
  const [expandedSeasons, setExpandedSeasons] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { seasons = [] } = episodesData || {};

  // Validate data
  useEffect(() => {
    try {
      if (!Array.isArray(seasons)) {
        throw new Error('Invalid seasons data');
      }
      if (!episodeTags || typeof episodeTags !== 'object') {
        throw new Error('Invalid episode tags data');
      }
      if (!Array.isArray(tags)) {
        throw new Error('Invalid tags data');
      }
      setError(null);
    } catch (error) {
      console.error('Data validation error:', error);
      setError('There was an error loading the episodes data. Please try refreshing the page.');
    }
  }, [seasons]);

  // Sort seasons in reverse order (latest first)
  const sortedSeasons = useMemo(() => {
    try {
      return [...seasons].sort((a, b) => {
        const seasonA = typeof a.season === 'string' ? parseInt(a.season) : a.season;
        const seasonB = typeof b.season === 'string' ? parseInt(b.season) : b.season;
        if (isNaN(seasonA) || isNaN(seasonB)) {
          throw new Error('Invalid season number');
        }
        return seasonB - seasonA;
      });
    } catch (error) {
      console.error('Error sorting seasons:', error);
      return seasons;
    }
  }, [seasons]);

  // Initialize state based on URL params and latest season
  useEffect(() => {
    try {
      const query = searchParams.get('search');
      const tagId = searchParams.get('tag');
      const matchingSeasons = new Set();

      if (tagId) {
        // Handle tag filter
        const validTag = tags.find(t => t.id === tagId);
        if (validTag) {
          setSelectedTags([tagId]);
          setSearchQuery(validTag.name);
          // Find matching seasons
          sortedSeasons.forEach(season => {
            if (!season || !Array.isArray(season.episodes)) {
              throw new Error('Invalid season data');
            }
            const hasMatch = season.episodes.some(episode => {
              const episodeTagIds = episodeTags[episode.id] || [];
              return episodeTagIds.includes(tagId);
            });
            if (hasMatch) {
              matchingSeasons.add(season.season);
            }
          });
        } else {
          setSearchParams({}); // Clear invalid tag from URL
        }
      } else if (query) {
        // Handle search query
        setSearchQuery(query);
        const matchingTag = tags.find(t => t.name.toLowerCase() === query.toLowerCase());
        if (matchingTag) {
          setSelectedTags([matchingTag.id]);
          sortedSeasons.forEach(season => {
            if (!season || !Array.isArray(season.episodes)) {
              throw new Error('Invalid season data');
            }
            const hasMatch = season.episodes.some(episode => {
              const episodeTagIds = episodeTags[episode.id] || [];
              return episodeTagIds.includes(matchingTag.id);
            });
            if (hasMatch) {
              matchingSeasons.add(season.season);
            }
          });
        } else {
          // Search by title
          sortedSeasons.forEach(season => {
            if (!season || !Array.isArray(season.episodes)) {
              throw new Error('Invalid season data');
            }
            const hasMatch = season.episodes.some(episode =>
              episode.title.toLowerCase().includes(query.toLowerCase())
            );
            if (hasMatch) {
              matchingSeasons.add(season.season);
            }
          });
        }
      } else if (sortedSeasons.length > 0) {
        // No filters, show latest season
        matchingSeasons.add(sortedSeasons[0].season);
      }

      setExpandedSeasons(matchingSeasons);
      setError(null);
    } catch (error) {
      console.error('Error initializing state:', error);
      setError('There was an error loading your search results. Please try again.');
      setSearchParams({});
      setSelectedTags([]);
      setSearchQuery('');
      if (sortedSeasons.length > 0) {
        setExpandedSeasons(new Set([sortedSeasons[0].season]));
      }
    }
  }, [searchParams, sortedSeasons]);

  const handleTagSelect = (tagId) => {
    setSelectedTags([tagId]);
    const tagName = tags.find(t => t.id === tagId)?.name || '';
    setSearchQuery(tagName);
    setSearchParams({ tag: tagId, search: tagName });
    
    // Find and expand seasons containing episodes with this tag
    const matchingSeasons = new Set();
    sortedSeasons.forEach(season => {
      if (!season || !Array.isArray(season.episodes)) {
        throw new Error('Invalid season data');
      }
      const hasMatch = season.episodes.some(episode => {
        const episodeTagIds = episodeTags[episode.id] || [];
        return episodeTagIds.includes(tagId);
      });
      if (hasMatch) {
        matchingSeasons.add(season.season);
      }
    });
    setExpandedSeasons(matchingSeasons);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    // Check if the search query matches any tag name
    const matchingTag = tags.find(t => 
      t.name.toLowerCase() === value.toLowerCase()
    );
    
    if (matchingTag) {
      handleTagSelect(matchingTag.id);
    } else {
      setSelectedTags([]);
      if (value) {
        setSearchParams({ search: value });
        // Find and expand seasons containing matching episodes
        const matchingSeasons = new Set();
        sortedSeasons.forEach(season => {
          if (!season || !Array.isArray(season.episodes)) {
            throw new Error('Invalid season data');
          }
          const hasMatch = season.episodes.some(episode =>
            episode.title.toLowerCase().includes(value.toLowerCase())
          );
          if (hasMatch) {
            matchingSeasons.add(season.season);
          }
        });
        setExpandedSeasons(matchingSeasons);
      } else {
        setSearchParams({});
        // Reset to latest season
        if (sortedSeasons.length > 0) {
          setExpandedSeasons(new Set([sortedSeasons[0].season]));
        }
      }
    }
  };

  const toggleSeason = (seasonNumber) => {
    setExpandedSeasons(prev => {
      const next = new Set(prev);
      if (next.has(seasonNumber)) {
        next.delete(seasonNumber);
      } else {
        next.add(seasonNumber);
      }
      return next;
    });
  };

  const filteredSeasons = useMemo(() => {
    return sortedSeasons.map(season => ({
      ...season,
      episodes: season.episodes.filter(episode => {
        const episodeTagIds = episodeTags[episode.id] || [];
        const matchesTags = selectedTags.length === 0 || 
          selectedTags.every(tag => episodeTagIds.includes(tag));

        // If we have selected tags, only filter by tags
        if (selectedTags.length > 0) {
          return matchesTags;
        }
        
        // Otherwise, filter by search query
        return episode.title.toLowerCase().includes(searchQuery.toLowerCase());
      })
    })).filter(season => season.episodes.length > 0);
  }, [sortedSeasons, searchQuery, selectedTags]);

  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
      <SEO
        title="Episodes - Indian History and Mythology Podcast"
        description="Listen to our collection of episodes about Indian history and mythology. From ancient civilizations to timeless myths."
      />

      <Container maxW="container.xl">
        <VStack spacing={12} align="stretch">
          {/* Header */}
          <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
            <Heading
              as="h1"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              lineHeight="1.2"
              variant="gradient"
              mb={{ base: 2, md: 4 }}
              fontWeight="bold"
            >
              Episodes
            </Heading>

            <Text 
              fontSize={{ base: "lg", md: "xl" }} 
              color="whiteAlpha.900" 
              maxW="2xl"
              px={{ base: 4, md: 0 }}
            >
              Explore our collection of stories from Indian history and mythology, carefully crafted to bring the past to life.
            </Text>
          </VStack>

          {/* Error Alert */}
          {error && (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              borderRadius="lg"
              p={6}
              bg="red.900"
              borderColor="red.500"
              borderWidth="1px"
            >
              <AlertIcon boxSize="6" mr={0} color="red.500" />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Oops! Something went wrong
              </AlertTitle>
              <AlertDescription maxWidth="sm" color="whiteAlpha.900">
                {error}
              </AlertDescription>
            </Alert>
          )}

          {/* Search and Filters */}
          {!error && (
            <>
              {/* Search Bar */}
              <Box maxW="2xl" mx="auto" w="full" px={4}>
                <InputGroup size="lg">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FaSearch} color="whiteAlpha.400" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search episodes..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    bg="gray.800"
                    border="1px solid"
                    borderColor="gray.700"
                    _hover={{
                      borderColor: 'gray.600'
                    }}
                    _focus={{
                      borderColor: 'brand.500',
                      boxShadow: 'none',
                      bg: 'gray.750'
                    }}
                    color="whiteAlpha.900"
                    _placeholder={{
                      color: 'whiteAlpha.400'
                    }}
                  />
                </InputGroup>
                {(searchQuery || selectedTags.length > 0) && (
                  <Box mt={2}>
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="whiteAlpha"
                      onClick={() => {
                        setSearchQuery('');
                        setSelectedTags([]);
                        setSearchParams({});
                      }}
                    >
                      Clear filters
                    </Button>
                    {selectedTags.length > 0 && (
                      <Text fontSize="sm" color="whiteAlpha.600" mt={2}>
                        Showing episodes with tag: {tags.find(t => t.id === selectedTags[0])?.name}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>

              {/* Tag Filter */}
              <Box maxW="2xl" mx="auto" w="full" px={4}>
                <TagFilter
                  selectedTags={selectedTags}
                  onTagSelect={handleTagSelect}
                />
              </Box>

              {/* Last Updated */}
              <Text fontSize="sm" color="whiteAlpha.600" textAlign="center">
                Last updated: {formatDate(lastUpdated.timestamp)}
              </Text>

              {/* Episodes List */}
              {isLoading ? (
                <VStack spacing={4} py={8}>
                  <Spinner size="xl" color="brand.500" />
                  <Text fontSize="lg" color="whiteAlpha.900">
                    Loading episodes...
                  </Text>
                </VStack>
              ) : filteredSeasons.length === 0 ? (
                <VStack spacing={4} py={8}>
                  <Icon as={FaExclamationTriangle} boxSize={8} color="yellow.500" />
                  <Text fontSize="lg" color="whiteAlpha.900">
                    No episodes found matching your search.
                  </Text>
                  <Button
                    variant="ghost"
                    colorScheme="brand"
                    onClick={() => {
                      setSearchQuery('');
                      setSelectedTags([]);
                      setSearchParams({});
                    }}
                  >
                    Clear search and filters
                  </Button>
                </VStack>
              ) : (
                <Box>
                  {filteredSeasons.map((season) => (
                    <Box
                      key={season.season}
                      border="1px solid"
                      borderColor="gray.700"
                      borderRadius="lg"
                      mb={4}
                      bg="gray.800"
                      _hover={{
                        borderColor: 'brand.500',
                      }}
                      transition="all 0.2s"
                    >
                      <Button
                        w="100%"
                        h="auto"
                        py={6}
                        px={6}
                        onClick={() => toggleSeason(season.season)}
                        bg="transparent"
                        _hover={{ bg: 'gray.750' }}
                        _active={{ bg: 'gray.700' }}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        borderRadius="lg"
                        borderBottomRadius={expandedSeasons.has(season.season) ? 0 : 'lg'}
                        borderBottom={expandedSeasons.has(season.season) ? '1px solid' : 'none'}
                        borderColor="gray.700"
                        transition="all 0.2s"
                      >
                        <VStack align="stretch" spacing={3} flex={1} maxW="calc(100% - 40px)">
                          <HStack spacing={2} width="100%" flexWrap="wrap" align="center">
                            <Badge
                              colorScheme="orange"
                              fontSize={{ base: "sm", md: "md" }}
                              px={3}
                              py={1}
                              borderRadius="full"
                              textTransform="none"
                              flexShrink={0}
                            >
                              Season {season.season}
                            </Badge>
                            <Text
                              fontSize={{ base: "lg", md: "2xl" }}
                              fontWeight="bold"
                              color="whiteAlpha.900"
                              flex={1}
                              isTruncated
                              textAlign="left"
                            >
                              {season.name}
                            </Text>
                            <Badge
                              colorScheme="gray"
                              fontSize={{ base: "xs", md: "sm" }}
                              px={2}
                              py={1}
                              borderRadius="full"
                              textTransform="none"
                              flexShrink={0}
                            >
                              {season.episodes.length} {season.episodes.length === 1 ? 'Episode' : 'Episodes'}
                            </Badge>
                          </HStack>
                          <Text
                            fontSize={{ base: "sm", md: "md" }}
                            color="brand.300"
                            fontStyle="italic"
                            textAlign="left"
                            wordBreak="break-word"
                            whiteSpace="pre-wrap"
                          >
                            {season.description}
                          </Text>
                        </VStack>
                        <Icon
                          as={FaChevronDown}
                          boxSize={{ base: 4, md: 6 }}
                          ml={4}
                          mt={2}
                          transform={expandedSeasons.has(season.season) ? 'rotate(180deg)' : 'none'}
                          transition="transform 0.2s"
                        />
                      </Button>

                      <Collapse in={expandedSeasons.has(season.season)}>
                        <Box py={6} px={{ base: 4, md: 6 }}>
                          <VStack spacing={4} align="stretch">
                            {season.episodes.map((episode) => (
                              <EpisodeCard
                                key={episode.id || episode.enclosure?.url}
                                episode={episode}
                                duration={parseDuration(episode.duration)}
                                isHighlighted={searchQuery && episode.title.toLowerCase().includes(searchQuery.toLowerCase())}
                              />
                            ))}
                          </VStack>
                        </Box>
                      </Collapse>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default Episodes;