import React from 'react';
import { 
  Container, 
  VStack, 
  Box, 
  SimpleGrid, 
  Link, 
  Icon,
  HStack,
  Divider,
  Image,
  Text,
  Heading,
} from '@chakra-ui/react';
import { 
  FaEnvelope, 
  FaWhatsapp, 
  FaSpotify, 
  FaYoutube,
  FaInstagram,
  FaFacebook,
  FaPaypal,
  FaRss,
} from 'react-icons/fa';
import { SiKofi, SiApplepodcasts } from 'react-icons/si';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';

const ContactLink = ({ icon, label, href, color = "brand.300", customIcon }) => (
  <Link 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer"
    _hover={{ textDecoration: 'none' }}
  >
    <HStack 
      spacing={3} 
      p={4}
      bg="gray.800"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.700"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-2px)',
        borderColor: color,
        bg: 'gray.700'
      }}
      align="center"
    >
      {customIcon ? (
        <Box 
          w={{ base: "24px", md: "28px" }} 
          h={{ base: "24px", md: "28px" }}
          position="relative"
        >
          <Image 
            src={customIcon} 
            alt={label} 
            objectFit="contain"
            width="100%"
            height="100%"
          />
        </Box>
      ) : (
        <Icon as={icon} boxSize={{ base: 5, md: 6 }} color={color} />
      )}
      <Text 
        fontSize={{ base: "sm", md: "md" }}
        color="whiteAlpha.900"
        fontWeight="medium"
      >
        {label}
      </Text>
    </HStack>
  </Link>
);

const ContactSection = ({ title, children }) => (
  <Box>
    <Heading
      as="h3"
      fontSize={{ base: "xl", md: "2xl" }}
      mb={6}
      bgGradient="linear(to-r, brand.500, purple.500)"
      bgClip="text"
      fontWeight="bold"
    >
      {title}
    </Heading>
    <VStack spacing={4} align="stretch">
      {children}
    </VStack>
  </Box>
);

const Contact = () => {
  return (
    <PageTransition>
      <SEO 
        title="Contact Us - Namaskar India"
        description="Connect with Namaskar India podcast through various social media platforms and support channels."
      />

      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <Container maxW="container.xl">
          <VStack spacing={{ base: 12, md: 16 }}>
            <VStack spacing={4} maxW="3xl" mx="auto" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
                lineHeight="1.2"
                bgGradient="linear(to-r, brand.500, purple.500)"
                bgClip="text"
                mb={{ base: 2, md: 4 }}
                fontWeight="bold"
              >
                Connect With Us
              </Heading>

              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                color="whiteAlpha.900" 
                maxW="2xl"
                px={{ base: 4, md: 0 }}
              >
                Join our community and stay updated with the latest episodes and announcements.
              </Text>
            </VStack>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} px={{ base: 4, md: 0 }}>
              {/* Get in Touch Section */}
              <ContactSection title="Get in Touch">
                <ContactLink
                  icon={FaEnvelope}
                  href="mailto:contact.namaskarindia@gmail.com"
                  label="Email Us"
                  color="whiteAlpha.900"
                  customStyle={{
                    bg: 'transparent',
                    borderWidth: '1px',
                    borderColor: 'whiteAlpha.300',
                    _hover: {
                      transform: 'translateY(-2px)',
                      borderColor: 'whiteAlpha.500',
                      bg: 'whiteAlpha.100'
                    }
                  }}
                />
                <ContactLink
                  icon={FaWhatsapp}
                  href="https://chat.whatsapp.com/H8IUJPlB32cA2soTjrTghV"
                  label="Join WhatsApp Group"
                  color="green.400"
                />
                <ContactLink
                  icon={FaWhatsapp}
                  href="https://chat.whatsapp.com/KVd5UHxumW90TxLHjkB89k"
                  label="WhatsApp Updates"
                  color="green.400"
                />
              </ContactSection>

              {/* Listen & Follow Section */}
              <ContactSection title="Listen & Follow">
                <Box 
                  borderWidth="1px" 
                  p={4} 
                  borderRadius="lg" 
                  bg="gray.700"
                  borderColor="gray.600"
                >
                  <Text fontSize="sm" fontWeight="medium" mb={3} color="whiteAlpha.900">
                    Listen on:
                  </Text>
                  <SimpleGrid columns={2} spacing={4}>
                    <ContactLink
                      icon={FaSpotify}
                      href="https://open.spotify.com/show/03UrDnDHONZ92oOpWzfhxx"
                      label="Spotify"
                      color="green.400"
                    />
                    <ContactLink
                      icon={SiApplepodcasts}
                      href="https://podcasts.apple.com/us/podcast/namaskar-india-tales-from-indian-history-and-mythology/id1516915527"
                      label="Apple"
                      color="purple.400"
                    />
                    <ContactLink
                      customIcon="/amazon-music-logo.png"
                      href="https://music.amazon.com/podcasts/3b2ce8d8-2c19-4b40-a9f3-3671d7e6ce82"
                      label="Amazon Music"
                    />
                    <ContactLink
                      customIcon="/gaana-logo.png"
                      href="https://gaana.com/podcast/namaskar-india-season-1"
                      label="Gaana"
                    />
                    <ContactLink
                      customIcon="/jiosaavn-logo.png"
                      href="https://www.jiosaavn.com/shows/namaskar-india/1/rj-QzdPFEEk_"
                      label="JioSaavn"
                    />
                    <ContactLink
                      icon={FaRss}
                      href="https://anchor.fm/s/1012c66c/podcast/rss"
                      label="RSS Feed"
                      color="orange.400"
                    />
                  </SimpleGrid>
                </Box>

                <Divider borderColor="gray.600" my={4} />

                <Text fontSize="sm" fontWeight="medium" mb={3} color="whiteAlpha.900">
                  Social Media:
                </Text>
                <SimpleGrid columns={2} spacing={4}>
                  <ContactLink
                    icon={FaYoutube}
                    href="https://www.youtube.com/c/NamaskarIndia"
                    label="YouTube"
                    color="red.400"
                  />
                  <ContactLink
                    icon={FaInstagram}
                    href="https://www.instagram.com/namaskarindialive/"
                    label="Instagram"
                    color="pink.400"
                  />
                  <ContactLink
                    icon={FaFacebook}
                    href="https://www.facebook.com/namaskarindialive"
                    label="Facebook"
                    color="blue.400"
                  />
                </SimpleGrid>
              </ContactSection>

              {/* Support Section */}
              <ContactSection title="Support Us">
                <VStack spacing={4} align="stretch">
                  <ContactLink
                    icon={SiKofi}
                    href="http://ko-fi.com/namaskarindia"
                    label="Support on Ko-fi"
                    color="#FF5E5B"
                  />
                  <ContactLink
                    icon={FaPaypal}
                    href="https://www.paypal.com/paypalme/aduppala"
                    label="PayPal"
                    color="#00457C"
                  />
                </VStack>

                <Box 
                  mt={4} 
                  p={4} 
                  bg="gray.700" 
                  borderRadius="lg"
                  borderWidth="1px"
                  borderColor="gray.600"
                >
                  <Text fontSize="sm" color="whiteAlpha.800">
                    UPI: 9893547492@paytm
                  </Text>
                </Box>
              </ContactSection>
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default Contact;
