import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import SEO from '../components/SEO';
import PageTransition from '../components/PageTransition';

const NotFound = () => {
  return (
    <PageTransition>
      <SEO
        title="404 - Page Not Found | Namaskar India"
        description="The page you're looking for doesn't exist."
      />

      <Box bg="gray.900" minH="100vh" py={{ base: 20, md: 24 }}>
        <Container maxW="container.xl">
          <VStack spacing={8} textAlign="center">
            <Heading
              as="h1"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              lineHeight="1.2"
              bgGradient="linear(to-r, brand.500, purple.500)"
              bgClip="text"
              mb={{ base: 2, md: 4 }}
              fontWeight="bold"
            >
              404 - Page Not Found
            </Heading>

            <Text 
              fontSize={{ base: "lg", md: "xl" }} 
              color="whiteAlpha.900" 
              maxW="2xl"
              px={{ base: 4, md: 0 }}
            >
              The page you're looking for doesn't exist. Let's get you back on track.
            </Text>

            <Button
              as={RouterLink}
              to="/"
              colorScheme="brand"
              size="lg"
              leftIcon={<Icon as={FaHome} />}
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'lg',
              }}
              transition="all 0.2s"
            >
              Return Home
            </Button>
          </VStack>
        </Container>
      </Box>
    </PageTransition>
  );
};

export default NotFound;
